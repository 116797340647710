@media screen and (max-width: 767px) {
  .FlexContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .GNB_BG {
    display: flex;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    width: 100%;
    height: 64px;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
  }

  .GNB_MenuBar_BG {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .GNB_Logo_CT {
    display: flex;
    z-index: 1;
    width: 144px;
    height: auto;
    margin-left: 6vw;
  }

  .GNB_Menu_CT {
    display: flex;
    z-index: 1;
    margin-right: 6vw;
    border-width: 0px;
    background-color: transparent;
    padding: 0;
    cursor: pointer; /* 커서 모양 변경 (선택사항) */
  }

  .GNB_Labels_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
    margin-right: 48px;
  }

  .GNB_MenuBarLabels_CT {
    display: flex;
    flex-direction: column;
    z-index: 3;
    margin-left: 6vw;
    margin-top: 24px;
  }

  .GNB_Label_CT {
    display: flex;
    z-index: 1;
    margin-right: 4vw;
    text-decoration: none;
    cursor: pointer; /* 커서 모양 변경 (선택사항) */
  }

  .GNB_MenubarLabel_CT {
    display: flex;
    flex-direction: column;
    z-index: 1;
    /* background-color: aqua; */
    margin-bottom: 16px;
    text-decoration: none;
    cursor: pointer; /* 커서 모양 변경 (선택사항) */
  }

  .GNB_MenubarLabel_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .GNB_MenubarLabel_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .GNB_Label_CT_Last {
    display: flex;
    z-index: 1;
  }

  .GNB_Label_Copy_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .GNB_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .GNB_MenubarLabel_CT_Last {
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .GNB_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
  }

  .GNB_MenuBarLabel_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
    width: 88vw;
    justify-content: space-between;
    align-items: center;
  }

  .GNB_MenuBar_CT {
    display: flex;
    height: 100%;
  }

  .Devider {
    background-color: rgb(227, 227, 227);
    width: 100%;
    height: 1px;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .MenuDevider {
    background-color: rgb(227, 227, 227);
    width: 100%;
    height: 1px;
    margin-top: 16px;
  }

  .NewsDevider {
    background-color: rgb(227, 227, 227);
    width: 100%;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .Home_CT {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    /* justify-content: flex-start; */
    margin-top: 64px;
  }

  .Home_Header_Image {
    display: flex;
    background-color: black;
    z-index: 1;
    width: 100%;
    height: 240px;
    /* height 추후 수정 필요 */
    align-items: center;
  }

  .Home_Header_Texts {
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    margin-left: -94%;
  }

  .Home_Header_Title {
    display: flex;
    z-index: 2;
    position: relative;
    /* margin-top: 10%; */
  }

  .Home_Header_Body {
    display: flex;
    z-index: 2;
    position: relative;
    width: 68vw;
    margin-top: 12px;
  }

  .Home_TechSummary_CT {
    display: flex;
    margin-top: 32px;
  }

  .Home_Partner_CT {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Home_Partner_BG {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgb(247, 249, 250);

    height: auto;
    width: 100%;
    /* margin-top: 50px; */

    overflow: hidden;
  }

  .Home_Partner_Image {
    display: flex;
    flex-direction: row;
    position: relative;

    max-width: 100%;
    margin-left: 50%;

    left: -20%;

    animation-name: Home_Partner_Image_Anime; /* 애니메이션 이름 설정 */
    animation-duration: 20s; /* 애니메이션 지속 시간 설정 */
    animation-timing-function: linear; /* 애니메이션 타이밍 함수 설정 */
    animation-fill-mode: forwards; /* 애니메이션 종료 후 유지되도록 설정 */
  }

  @keyframes Home_Partner_Image_Anime {
    0% {
      transform: translate(0%);
    }

    50% {
      transform: translate(-455%);
    }

    100% {
      transform: translate(-910%);
    }
  }

  .About_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: 64px;
  }

  .About_Header_Image {
    display: flex;
    width: 100%;
    height: 64px;
    align-items: center;
    visibility: hidden;
  }

  .About_Paragraph_1_CT {
    display: flex;
    width: 86vw;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 120px;
  }

  .About_Paragraph_2_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-top: 120px; */
  }

  .About_Subject {
    display: flex;
    width: 135px;
  }

  .About_Texts {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .About_Title {
    display: flex;
    width: 86vw;
    margin-bottom: 8px;
  }

  .About_Body {
    display: flex;
    width: 86vw;
  }

  .About_History_CT {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    width: 86vw;
  }

  .About_DotImage {
    display: flex;
    width: 16px;
    height: 324px;
    opacity: 0.5;
  }

  .About_History_Texts {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  .About_History_Text {
    display: flex;
    flex-direction: row;
    margin-bottom: 24.75px;
    align-items: center;
  }

  .About_History_Text_Last {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .About_History_Date {
    display: flex;
    margin-right: 16px;
  }

  .About_History_Body {
    display: flex;
  }

  .Tech_Image {
    display: flex;
    width: 86vw;
    height: auto;
    margin-top: 24px;
  }

  .Product_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Product_Banner_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 84vw;
    margin-top: 32px;
  }

  .Product_Contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 84vw;
    margin-top: 64px;
  }

  .Product_Texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .Product_BodyText {
    display: flex;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .Product_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-width: 0px;
    background-color: transparent;
    cursor: pointer;
  }

  .Product_TextButton_Text {
    display: flex;
  }

  .Product_TextButton_Icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
  }

  .Product_Buttons_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    margin-top: 10vw;
  }

  .Product_Apple {
    display: flex;
    margin-bottom: 5vw;
  }

  .Product_Google {
    display: flex;
  }

  .Product_Button_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: rgb(50, 50, 50);
    border: none;
    border-radius: 12px;

    height: 64px;
    width: 180px;
    cursor: pointer;
  }

  .Product_Illust {
    display: none;
    justify-content: flex-end;
    align-items: center;
  }

  .News_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    margin-top: 32px;
  }

  .News_Paragraph_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
    margin-top: 48px;
  }

  .News_Subject {
    display: flex;
    margin-bottom: 48px;
  }

  .News_CenterAlign_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .News_Script {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .News_Script_Image {
    display: flex;
    width: 86vw;
    height: 50vw;
    border-radius: 16px;
    overflow: hidden;
  }

  .News_Script_Texts {
    display: flex;
    flex-direction: column;
  }

  .News_Script_InfoText {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: center;
  }

  .News_Script_Media {
    display: flex;
    margin-right: 12px;
  }

  .News_Script_Media_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    letter-spacing: -3%;
  }

  .News_Script_Date {
    display: flex;
  }

  .News_Script_Date_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    letter-spacing: -3%;
  }

  .News_Script_Title {
    display: flex;
    width: 86vw;
    margin-bottom: 8px;
  }

  .News_Script_Title_Copy {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -3%;
  }

  .News_Script_Body {
    display: flex;
    width: 86vw;
  }

  .News_Script_Body_Copy {
    color: rgb(120, 120, 120);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -3%;
  }

  .News_Pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .News_Pagination_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 37px;
  }

  .News_PaginationCT_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .Partner_Image {
    display: flex;
    width: 88vw;
    height: auto;
  }

  .Contact_Contents {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 86vw;
  }

  .Contact_Texts {
    display: flex;
    flex-direction: column;
    width: 86vw;
    margin-bottom: 32px;
  }

  .Contact_Email {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .Contact_Adress {
    display: flex;
    flex-direction: column;
    max-width: 88vw;
  }

  .Contact_gform_CT {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Contact_FormContents {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Contact_BG {
    display: flex;
    flex-direction: column;
    width: 79vw;
    border-radius: 16px;
    background-color: rgb(61, 61, 61);
    border: none;
    justify-content: center;
    align-items: center;
    /* margin-left: 32px; */
    margin: 0;
  }

  .Contact_Subject_CT_First {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    margin-top: 48px;
  }

  .Contact_Subject_CT {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
  }

  .Contact_Label {
    display: flex;
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
    width: 86px;
    margin-bottom: 12px;
  }

  .Contact_Input {
    width: 70vw;
    height: 32px;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    /* margin-left: 48px; */
    padding: 12px;

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Contact_Input::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Contact_Message {
    width: 70vw;
    height: 128px;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    /* margin-left: 48px; */
    padding: 12px;

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Contact_Success_CT {
    display: none;
  }

  .Contact_Success_Text {
    display: flex;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: center;
    width: 70vw;
    word-break: keep-all;
    margin-bottom: 24px;
    line-height: 130%;
  }

  .Contact_Button {
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;

    background-color: rgb(61, 61, 61);
    width: 240px;
    height: 64px;
    border-radius: 16px;
    border: none;

    margin-top: 48px;
    cursor: pointer;
  }

  .Copy_1_200_A {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 200;
    font-size: 32px;
    text-align: left;
    word-break: keep-all;
    line-height: 130%;
  }

  .Copy_1_600_A {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -3%;
  }

  .Copy_2_600_A {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Copy_4_300_A {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    word-break: keep-all;
    text-align: left;
  }

  .Copy_4_300_B {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    word-break: keep-all;
    text-align: left;
  }

  .Copy_4_400_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    word-break: keep-all;
    text-align: left;
    line-height: 130%;
  }

  .Copy_4_600_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    word-break: keep-all;
    text-align: left;
    line-height: 130%;
  }

  .Copy_5_300_A {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
  }

  .Copy_5_300_B {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -3%;
    word-break: keep-all;
  }

  .Copy_5_400_A {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .Copy_5_600_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Copy_5_600_B {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -3%;
    height: 32px;
  }

  .Copy_5_600_C {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Copy_5_600_D {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 32px;
  }

  .Copy_5_600_E {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    height: 32px;
    padding-left: 0;
  }

  .Copy_5_600_F {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Copy_6_500_A {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    justify-content: center;
    text-align: center;
  }

  .Copy_7_400_A {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    word-break: keep-all;
    justify-content: center;
  }

  .Footer_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20vw;
    margin-bottom: 32px;
    width: 100%;
  }

  .Footer_Logo {
    display: flex;
    z-index: 1;
    width: 96px;
    height: auto;
    margin-left: 7vw;
  }

  .Footer_Texts {
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-left: 7vw;
    margin-top: 16px;
    max-width: 320px;
  }

  .Footer_Text {
    display: flex;
    z-index: 1;
    margin-bottom: 4px;
  }
}
